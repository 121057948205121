import store from '@/store'; // eslint-disable-line
import axios from 'axios';
import environmentConfig from '../config/environment-config';

const httpService = axios.create({
  baseURL: environmentConfig.VUE_APP_API_BASE_URL,
});

httpService.interceptors.request.use((config) => {
  const token = store.state.oidcStore.access_token;

  return { ...config, headers: { Authorization: `Bearer ${token}` } };
});

export default httpService;
