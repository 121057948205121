const getConfig = () => {
  const stage = process.env.VUE_APP_STAGE;
  switch (stage) {
    case 'local':
      return {
        VUE_APP_AUTH_CLIENT_ID: 'tohwrdOcpOoXClnCVLUQCjhtqffsrDtZYXgo18Qr9YbVWJCXyHeL0eMzHQQmdCMj',
        VUE_APP_API_BASE_URL: 'http://localhost:4000/api',
        VUE_APP_AUTH_URL: 'https://sso-corproot-v2-mfa.dev-scapp.swisscom.com/oauth/authorize',
        VUE_APP_USER_INFO_URL: 'https://sso-corproot-v2-mfa.dev-scapp.swisscom.com/userinfo',
        VUE_APP_JWKS_URL: 'https://sso-corproot-v2-mfa.dev-scapp.swisscom.com/token_keys',
        VUE_APP_ALLOWED_EXTERNAL: 'taaziya1;taahumii;taaegki1;taaorse5;taatalu8',
      };
    case 'development':
      return {
        VUE_APP_AUTH_CLIENT_ID: 'tohwrdOcpOoXClnCVLUQCjhtqffsrDtZYXgo18Qr9YbVWJCXyHeL0eMzHQQmdCMj',
        VUE_APP_API_BASE_URL: 'https://supplierplattform-api.dev-scapp.swisscom.com/api',
        VUE_APP_AUTH_URL: 'https://sso-corproot-v2-mfa.dev-scapp.swisscom.com/oauth/authorize',
        VUE_APP_USER_INFO_URL: 'https://sso-corproot-v2-mfa.dev-scapp.swisscom.com/userinfo',
        VUE_APP_JWKS_URL: 'https://sso-corproot-v2-mfa.dev-scapp.swisscom.com/token_keys',
        VUE_APP_ALLOWED_EXTERNAL: 'taaziya1;taahumii;taaegki1;taaorse5;taatalu8',
      };
    case 'production':
      return {
        VUE_APP_AUTH_CLIENT_ID: 'dHE5c0aJrlDz5FjarSeGuFV8RDLEG0zdtO1rKW2c9Id8JWAhsn9GwTgh3rRhHbz7',
        VUE_APP_API_BASE_URL: 'https://supplierplattform-api.scapp.swisscom.com/api',
        VUE_APP_AUTH_URL: 'https://sso-corproot-v2-mfa.scapp-services.swisscom.com/oauth/authorize',
        VUE_APP_USER_INFO_URL: 'https://sso-corproot-v2-mfa.scapp-services.swisscom.com/userinfo',
        VUE_APP_JWKS_URL: 'https://sso-corproot-v2-mfa.scapp-services.swisscom.com/token_keys',
        VUE_APP_ALLOWED_EXTERNAL: 'taaziya1;taahumii;taaegki1;taaorse5;taatalu8',
      };
    default:
      throw new Error('No stage specified');
  }
};

export default getConfig();
