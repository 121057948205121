/* eslint-disable */

import environmentConfig from '../../config/environment-config';

export default {
  name: 'AccessForbidden',
  mounted() {
    const { currentUser } = this.$store.state.User;

    const allowedExtUsers = environmentConfig.VUE_APP_ALLOWED_EXTERNAL.split(';');

    if (!Number(currentUser.isExternal) || allowedExtUsers.filter((user) => user.toLowerCase() === currentUser.username.toLowerCase()).length > 0) {
      this.$router.replace('/');
    }
  },
};
