import { mapActions, mapGetters, mapMutations } from 'vuex';
import Card from '../../components/Card/Card.vue';
import DeprecationHeader from '../../components/DeprecationHeader/DeprecationHeader.vue';
import Footer from '../../components/Footer/Footer.vue';
import OverviewHeader from '../../components/OverviewHeader/OverviewHeader.vue';

export default {
  name: 'SupplierOverview',

  components: {
    OverviewHeader,
    Card,
    Footer,
    DeprecationHeader,
  },
  data() {
    return {
      busy: false,
    };
  },
  created() {
    if (this.$route.query.firstName && this.$route.query.lastName) {
      const search = `${this.$route.query.firstName} ${this.$route.query.lastName}`;
      this.setSearchQuery(search);
      this.setFilterType('CONTACTS');
    }

    this.loadSuppliers();
  },
  methods: {
    ...mapMutations(['setSearchQuery', 'setFilterType', 'getClickedSupplier']),
    ...mapActions(['loadSuppliers', 'loadMoreSuppliers']),
    async loadMore() {
      this.loadMoreSuppliers();
    },
  },
  computed: {
    ...mapGetters('oidcStore', ['oidcIsAuthenticated', 'oidcAuthenticationIsChecked', 'oidcUser', 'oidcAccessToken', 'oidcIdTokenExp']),
    ...mapGetters(['getSuppliers', 'getFilterType', 'isLoading', 'isLoadingMore']),
  },
};
