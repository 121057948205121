import Oidc from 'oidc-client';
import environmentConfig from './environment-config';

const oidcSettings = {
  authority: environmentConfig.VUE_APP_AUTH_URL,
  client_id: environmentConfig.VUE_APP_AUTH_CLIENT_ID,
  userStore: new Oidc.WebStorageStateStore({
    store: window.localStorage,
  }),
  redirectUri: `${window.location.protocol}//${window.location.host}/oidc/callback`,
  responseType: 'token',
  scope: 'openid',
  accessTokenExpiringNotificationTime: 10,
  loadUserInfo: false,
  metadata: {
    authorization_endpoint: environmentConfig.VUE_APP_AUTH_URL,
    userinfo_endpoint: environmentConfig.VUE_APP_USER_INFO_URL,
    jwks_uri: environmentConfig.VUE_APP_JWKS_URL,
  },
};
export default oidcSettings;
